.logoScroll {
position: fixed;
top: 45vh;
left: -3.5%;
width: 107vw;
transition: all 0.5s ease;
}

.logoScrolled {
position: fixed;
top: 1rem;
left: 1rem;
width: 200px;
transition: all 0.5s ease;
z-index: 1031;
}
