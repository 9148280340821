/* Container setup */
.logo-container {
position: relative;
display: flex;
justify-content: center;
align-items: center;
height: 4.5rem;
}

/* Row styling */
.row-container {
display: flex;
justify-content: space-between;
max-width: 80%;
transition: all 0.5s ease;
}

/* When 1 and 6 move closer together */
.row-container.closer {
justify-content: space-around;
}

/* Box styling */
.colLogo {
width: auto;
height: 50px;
color: red;
display: flex;
justify-content: center;
align-items: center;
transition: transform 0.5s ease, opacity 0.5s ease;
padding: 0 .15rem;
}

/* Animation: Move divs up */
.move-up {
transform: translateY(-100px);
opacity: 0;
}


/* Animation: Hide divs */
.hide {
opacity: 0;
pointer-events: none;
transform: scale(0.8);
}

.move-right-1 {
transform: translateX(100%);
opacity: 0;
}
.move-right-2 {
transform: translateX(200%);
opacity: 0;
}
.move-right-3 {
transform: translateX(250%);
}
.move-left-1 {
transform: translateX(-100%);
opacity: 0;
}
.move-left-2 {
transform: translateX(-200%);
opacity: 0;
}
.move-left-3 {
transform: translateX(-300%);
opacity: 0;
}