.letstalkCta{
display: flex; 
width: 400px; 
height: 100px; 
padding: 20px 0px; 
flex-direction: column; 
justify-content: center; 
align-items: center; 
gap: 10px;
border-radius: 62px;
background: #5C31D3;
color: #F2FF6E;
text-align: center;
font-size: 35px;
line-height: 140%; /* 49px */
letter-spacing: 2.45px;
text-decoration: none;
transition: All .3s;
}

.letstalkCta:hover{
background: #9d8cf9;
color: #502cc6;
}