/*menu*/
.menu-container {  
position: relative;  
display: flex;  
flex-direction: row-reverse;
}

.menu-toggle {
background-color: rgba(0,0,0,0);
border: none;
padding: .25rem 1rem;
cursor: pointer;
position: relative;
z-index: 2001; /* Ensure the button is above the menu */
}

.menu-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: right;
align-items: center;
transform: translateY(-100%);
transition: transform 0.3s ease;
z-index: 1030; /* Sits behind the button */
}

.menu-overlay.open {
transform: translateY(0); /* Slide down when open */
}

.menu {
width: 100%;
max-width: 90%;
text-align: left;
margin-right: 1em;
}

.menu ul {
list-style: none;
padding: 0;
margin: 0;
color: #fff;
}

.menu li {
padding: 15px 0;
}

.menu a {
color: #fff;
text-decoration: none;
display: block;
font-style: normal;
}

.menu-close {
background: none;
border: none;
position: absolute;
}

.navbarBg { 
background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(23,28,36,1) 0%, rgba(0,0,0,0));
height: 3.5rem;
z-index: 1029!important;
}

@media (min-width: 992px){
.menu {
max-width: 50%;
}
}