@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

body {margin: 0; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 16px; background-color: #171C24;}
.container{max-width: 1400px;}

.roboto-serif-regular {font-family: 'Roboto Serif', serif; font-optical-sizing: auto; font-weight: 400; font-style: normal; font-variation-settings: 'wdth' 100, 'GRAD' 0; }
.work-sans-bold {font-family: 'Work Sans', sans-serif;  font-optical-sizing: auto;  font-weight: 700;  font-style: normal;}
.work-sans-semi-bold {font-family: 'Work Sans', sans-serif;  font-optical-sizing: auto;  font-weight: 600;  font-style: normal;}
.work-sans-medium {font-family: 'Work Sans', sans-serif;  font-optical-sizing: auto;  font-weight: 500;  font-style: normal;}
.work-sans-regular {font-family: 'Work Sans', sans-serif;  font-optical-sizing: auto;  font-weight: 400;  font-style: normal;}
.inter-extra-bold { font-family: 'Inter', sans-serif; font-optical-sizing: auto; font-weight: 800; font-style: normal; line-height: 127%; letter-spacing: 0.1em;}
.inter-bold { font-family: 'Inter', sans-serif; font-optical-sizing: auto; font-weight: 700; font-style: normal; line-height: 100%; letter-spacing: 0.1em;}
.inter-regular { font-family: 'Inter', sans-serif; font-optical-sizing: auto; font-weight: 400; font-style: normal; line-height: 127%; /* 4.875rem */ letter-spacing: 1.4px;}
.open-sans-extra-bold  { font-family: 'Open Sans', serif; font-optical-sizing: auto; font-weight: 800; font-style: normal; font-variation-settings: 'wdth' 100; }

.bg-blackpearl{background-color:#171C24;}
.bg-none{--bs-card-bg:(0,0,0,0);background-color:(0,0,0,0);}
.bg-purple-heart{background-color:rgba(92,49,211,1);}
.bg-biloba-flower{background-color:#A896FF;}
.bg-scotch-mist{background-color:#F3EBC7;}
.bg-quartz{background-color:#DED6F6;}
.bg-75{--bs-bg-opacity: 0.75!important;}

.laserlemon{color:#F2FF6E;}
.bilobaflower{color:#A896FF;}
.quartz{color:#DED6F6;}
.purple-heart{color:#5C31D3;}
.purple-heart-hover:hover{color:#5C31D3!important;}
.scotch-mist{color:#F3EBC7;}

.fs-0{font-size: 0;}
.fs-14{font-size: 0.875rem;}
.fs-18{font-size: 1.125rem;}
.fs-20{font-size: 1.25rem; line-height: 1.75rem;}
.fs-24{font-size: 1.5rem;}
.fs-26{font-size: 1.625rem;}
.fs-34{font-size: 2.125rem;}
.fs-36{font-size: 2.25rem;}
.fs-38{font-size: 2.375rem;}
.fs-40{font-size: 2.5rem;}
.fs-45{font-size: 2.8125rem;}
.fs-60{font-size: 3.75rem;}
.fs-64{font-size: 4rem;}

.lh-100{line-height: 100%;}
.lh-120{line-height: 120%;}

.p-20{padding: 1.25rem;}
.p-24{padding: 1.5rem;}
.p-30{padding: 1.875rem;}
.p-40{padding: 2.5rem;}
.pb-20{padding-bottom: 1.25rem;}
.pb-40{padding-bottom: 2.5rem;}
.py-80{padding-bottom: 5rem;padding-top: 5rem;}
.py-40{padding-bottom: 2.5rem;padding-top: 2.5rem;}
.px-20{padding-right: 1.25rem;padding-left: 1.25rem;}
.px-40{padding-right: 2.5rem;padding-left: 2.5rem;}
.py-100{padding-bottom: 6.25rem;padding-top: 6.25rem;}


//madethis

h1{font-family: 'Work Sans';font-style: normal;font-weight: 700;line-height: 80%; /* 154.51px */ letter-spacing: normal; font-size: 6.25rem;}
h2{font-family: 'Work Sans';font-style: normal;font-weight: 700;line-height: 80%; /* 154.51px */ letter-spacing: normal; font-size: 2.75rem;}
h3{font-family: 'Work Sans';font-style: normal;font-weight: 700;line-height: 120%; /* 154.51px */ letter-spacing: normal; font-size: 1.375rem;}
h4{font-family: 'Work Sans';font-style: normal;font-weight: 700;line-height: 120%; /* 154.51px */ letter-spacing: normal; font-size: 1.125rem;}
p{margin: 0;}

.nav-link{ font-size: 1.25rem ;} 
.card-text-14{font-size: 0.875rem;}
.card-text-18{font-size: 1.125rem;}

.w-105vw{width: 105vw;}
.w-300p{width: 300px;}
.text-justify{text-align: justify;}
.text-parism{color: #2E2161;}

.zi-333{z-index: 333;}
.zi-444{z-index: 444;}
.zi-555{z-index: 555;}
.zi-999{z-index: 999;}
.zi-1000{z-index: 1000;}

.bg-radicalred{background-color: #ff334c;}
.bg-parism{background-color: #2E2161;}
.bg-snugg-greadient{background: linear-gradient(183deg, #FDD4F4 8.14%, #FDE7F9 92.57%);}
.bg-black-greadient{background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);}


.border-parism{border-color: #2E2161!important;;--bs-border-opacity: 1;}
.anchor { display: block;  position: relative;  top: -200px;  visibility: hidden;}
.cursor-no{cursor: none!important;}

/*logo*/
.logoHome{ width: 107vw; position: relative; transition: width .5s;}
.logoHome.scrolled{width: 33%; position: fixed; top: 1.15rem; left: 1.15rem; transition: width .5s;}

.logoWrapp{ width: 107vw; transition: width .5s;}
.logoWrapp.scrolled{ width: auto; transition: width .5s; z-index: 1031;}

/*--video-background--*/
.video-container {
position: relative;
width: 100%;
height: 100vh; /* Full viewport height */
overflow: hidden;
}

.video-background, .video-background video{
position: absolute;
top: 50%;
left: 50%;
width: 100%;
height: 100%;
object-fit: cover; /* Makes the video cover the entire div */
transform: translate(-50%, -50%);
z-index: -1; /* Puts the video behind any other content */
}

/* ExpandableVideo.css */

/* Overlay container for the expanded video */

.video-overlay {
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.9);
transition: all 0.5s ease;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
z-index: 1040;
}

/* Expanded video styling */
.video-expanded {
max-width: 100%;
max-height: 100%;
}

/* Close button styling */
.close-button {
position: absolute;
top: .25rem;
right: .25rem;
font-size: 40px;
color: white;
background: none;
border: none;
cursor: pointer;
z-index: 1001;
}

/* Modal Overlay (Full-Screen Background) */
.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.9);
transition: all 0.5s ease;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
z-index: 1040;
}

/* Full-Screen Modal Content */
.modal-fullscreen {
position: relative;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background: #000;
}

.video-player {
max-width: 100%;
max-height: 100%;
outline: none;
}

.video-preloader {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: rgba(0, 0, 0, 0.8);
z-index: 1050;
}

/* svg icon*/

.svg-icon {
cursor: pointer;
width: 50px;
height: 50px;
fill: #A896FF;
position: absolute;
transition: All 0.3s;
}

.svg-icon:hover {
fill: #f1ff75;
.svg-icon-path{
transform: scale(1.25);
transform-origin: 45% 50%;
}
}

.svg-icon-path{
transition: All 0.3s;
}

/*articles*/

.article{
display: flex; 
align-items: flex-start; 
}

.article-thumbnail-bg {
background-size: cover;
background-position: center;
border-radius: .75rem;
height: 100%;
min-height: 21.25rem;
width: 100%;
}

.article-thumbnail {
display: flex;
height: 100%;
min-height: 13.625rem;
width: 100%;
align-items: center;
justify-content: center;
border-radius: .75rem;
transition: all 0.3s ease;
background-size: cover;
background-position: center;
margin: 0;
}

.article-thumbnail-color {
background-color: rgba(92,49,211,.6);
}

/*tiles*/
.tiles {
display: flex; 
align-items: flex-start; 
gap: 2.5rem; 
justify-content: center;
}

.tiles-thumbnail {
cursor: pointer;
display: flex;
height: 100%;
min-height: 13.625rem;
width: 100%;
align-items: center;
justify-content: center;
border-radius: .75rem;
transition: all 0.3s ease;
background-size: cover;
background-position: center;
margin: 0;
}

.tiles-thumbnail img {
border-radius: .75rem;
}

/*contact*/
.socialIcon {
fill: #171C24;
transition: All 0.3s;
}
.socialIcon:hover {
fill: #502cc6;
}

@media (min-width: 992px){
.p-lg-100{padding: 6.25rem!important;}
.px-lg-100{padding-left: 6.25rem!important;padding-right: 6.25rem!important;}
.pt-lg-100{padding-top: 6.25rem!important;}
.pb-lg-100{padding-bottom: 6.25rem!important;}
.mb-lg-100{margin-bottom: 6.25rem!important;}
.g-lg-50{padding: 3.125rem!important;}
.fs-lg-26{font-size: 1.625rem!important;}
.fs-lg-36{font-size: 2.25rem!important;}
.fs-lg-56{font-size: 4.063rem!important;}

//--

h1{font-size: 12rem;}
h2{font-size: 3.75rem;}
h3{font-size: 2rem;}

.fs-lg-24{font-size: 1.5rem;}
.fs-lg-46{font-size: 2.875rem;}
.fs-lg-60{font-size: 3.75rem;}

.card-img-top{ width: 100%;}
.card-text-18{font-size: 1.5rem;}
.react-multiple-carousel__arrow--left {left:85%}
.react-multiple-carousel__arrow--right {right:5%}

.p-lg-80{padding: 80px;}
.px-lg-20{padding-left: 1.25rem;padding-right:  1.25rem;}
.p-lg-40{padding: 2.5rem;}
.h-lg-352{height: 22rem;}
.logoHome.scrolled{width: 180px;}


.w-lg-50{width: 50%!important;}
.w-lg-75{width: 75%!important;}
}
